import React from 'react';
// import heroimage from "../hero1.jpg";
import Header from '../Header';

import Card from '../Card';
import '../style.css';
import Values from '../Values';
import Director from '../Director';
import Footer from '../Footer';
import Navbar from '../Navbar';
import hero1 from "../hero1.jpg"
import hero2 from "../hero2.jpg"
import hero3 from "../hero3.jpg"
import Sdata from '../Servicedata';
import Vdata from '../Valuesdata';
import Ddata from '../Directordata';
// import RecentBlog from '../RecentBlog';
// import recentblog from '../RecentBlogData';
import QueryForm from '../Queryform';
import ScrollToTop from'../ScrollToTop';


function Home() {
    return(
        <>
        <Header/>
        <Navbar />
   
    
    {/* <div class='Logoportion'>
   <img src={companyLogo} alt='Logo' Class='logo'/>
   <nav class="navbar" >
     <ul class='navbar-list'>
      <li><Link to="/" class='link'>Home</Link></li>
      <li><Link to="/about" class='link'>About us</Link></li>
      <li class='navli'><Link to="/services" class='link'>Services</Link><div><RiArrowDropDownLine  class='dropdown' /></div></li>
      <li><Link to="/client" class='link'>Clients</Link></li>
      <li><Link to="/team" class='link'>Team</Link></li>
      <li><Link to="/blog" class='link'>Blog</Link></li>
      <li><Link to="/contactus" class='link'>Contact us</Link></li>
     </ul>
    </nav>
   </div> */}
  <div >
    <div id="carouselExampleFade" class="carousel slide  " data-bs-ride="carousel" >
  <div class="carousel-inner">
    <div class="carousel-item active">
      <h1 className='Tagline'>Your Trusted Partner<br></br> in Financial Solutions</h1>

      <img src={hero1} class="d-block w-100 " alt="Chartered Accountant"/>
    </div>
    <div class="carousel-item">
      <img src={hero2} class="d-block w-100 " alt="Audit"/>
    </div>
    <div class="carousel-item">
      <img src={hero3} class="d-block w-100 " alt="Starup Services"/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
    <span class="carousel-control-prev-icon " aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

</div>         
 
 <div class='first-content'>   
     <div class='heading-height'><h1 class='heading'>Welcome to Mohindra Arora & Co.</h1> </div>    
     <div>
     <p class='content'>Chartered Accountant</p>
     </div>
     <div class='paraspace'>
     <p class="bodytext">Mohindro Arora and Co. is a renowned chartered accountancy firm located in New Delhi. With a legacy of excellence and a commitment to integrity, we have been serving clients with top-notch financial and consultancy services for several decades. Our team of highly skilled professionals brings a wealth of experience and expertise to every engagement, ensuring that our clients receive the best possible solutions tailored to their unique needs.</p>
     </div>
     
   </div>

   <div class='service-section'>

    <div class='service-line-height'>
     <h1 class='service-heading'> Our <span> Services</span></h1>
     <p class='service-text'>We offer a comprehensive suite of services to meet all your financial and regulatory requirements: </p>
     </div> 
     <div class='card-align'>
     <Card img={Sdata[0].img} heading={Sdata[0].heading} para={Sdata[0].para}/>
     <Card img={Sdata[1].img} heading={Sdata[1].heading} para={Sdata[1].para}/>
     <Card img={Sdata[2].img} heading={Sdata[2].heading} para={Sdata[2].para}/>
    </div>
    <div class='card-align'>
     <Card img={Sdata[3].img} heading={Sdata[3].heading} para={Sdata[3].para}/>
     <Card img={Sdata[4].img} heading={Sdata[4].heading} para={Sdata[4].para}/>
     <Card img={Sdata[5].img} heading={Sdata[5].heading} para={Sdata[5].para}/>
    </div>
    <div class='card-align'>
     <Card img={Sdata[6].img} heading={Sdata[6].heading} para={Sdata[6].para}/>
     <Card img={Sdata[7].img} heading={Sdata[7].heading} para={Sdata[7].para}/>
     <Card img={Sdata[8].img} heading={Sdata[8].heading} para={Sdata[8].para}/>
    </div>

   </div>

   <div class='card2-align'>
   <Values img={Vdata[0].img} heading={Vdata[0].heading} para={Vdata[0].para}/>
   <Values img={Vdata[1].img} heading={Vdata[1].heading} para={Vdata[1].para}/>
   <Values img={Vdata[2].img} heading={Vdata[2].heading} para={Vdata[2].para}/>
   </div>

   <div class='team-section'>
   <p class='service-heading'>Our <span>Team</span></p>
   
   <div class='dir-multi'>
   <Director img={Ddata[0].img} name={Ddata[0].name} profile={Ddata[0].profile}/>
   <Director img={Ddata[1].img} name={Ddata[1].name} profile={Ddata[1].profile}/>
   <Director img={Ddata[2].img} name={Ddata[2].name} profile={Ddata[2].profile}/>
   <Director img={Ddata[3].img} name={Ddata[3].name} profile={Ddata[3].profile}/>
   <Director img={Ddata[4].img} name={Ddata[4].name} profile={Ddata[4].profile}/>
   </div>
   </div>
{/* 
   <div class="recent-blogs-portion">
   <h1 class='service-heading'>Latest News and <span>Blogs</span></h1>
   <div class='blog-home-align'  >
    <RecentBlog img={recentblog[0].img} topic={recentblog[0].topic} content={recentblog[0].content}/>
    <RecentBlog img={recentblog[1].img} topic={recentblog[1].topic} content={recentblog[1].content}/>
    <RecentBlog img={recentblog[2].img} topic={recentblog[2].topic} content={recentblog[2].content}/>
   </div>
  </div> */}

  <h1 class='service-heading'> Contact <span> Us</span></h1>
  <div class='get-touch'> 
   <QueryForm/>
     <div class='map'>
     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.302631765129!2d77.15758607457353!3d28.650655883259166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d036fccf90297%3A0x1c262c20e33091b8!2sMOHINDRA%20ARORA%20AND%20CO!5e0!3m2!1sen!2sin!4v1718265048597!5m2!1sen!2sin" width="600" height="450"  allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='MohindraMap'></iframe>
     </div>
     </div>
     
     <ScrollToTop />
     <Footer />
   
</>
    );
}

export default Home ;