import MGArora from'./images/MGArora.png'; 
import Dilbagh from'./images/Dilbaghsingh.png';
import AshokTayal from'./images/MAAC-Partners-Ashok_Tayal.png';
import SanjulaGupta from'./images/MAAC-Partners-sanjula_gupta.png';
import Yashpalsood from'./images/MAAC-Partners-yashpal_sood.png';


const Ddata=[
    {
        img:MGArora,
        name:" CA Madan Arora  ",
        profile:"Partner"
    },
    {
        img:AshokTayal,
        name:" CA Ashok Tayal",
        profile:"Partner"
    },
    {
        img:Yashpalsood,
        name:"CA Yashpal Sood",
        profile:"Partner"
    },
    {
        img:SanjulaGupta,
        name:"CA Sanjula Gupta",
        profile:"Partner"
    },
    {
        img:Dilbagh,
        name:" CA Dilbagh Singh",
        profile:"Partner"
    },

]

export default Ddata;